import { makeStyles } from "@material-ui/core";
import React from "react";
import fundo from "../../../../assets/images/giftcards/bannermobile.png";
import woman from "../../../../assets/images/giftcards/woman.png";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";
import useGetPlans from "../../../../hooks/useGetPlans";

const useStyles = makeStyles({
  // container: {
  //   backgroundColor: "#F4F4F4",
  //   height: "inherit",
  //   paddingTop: "20px",
  //   paddingBottom: "20px",
  // },
  container: {
    backgroundImage: `url(${fundo})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "1100px",
    marginTop: "20px",
    position: "relative",
    backgroundColor: "#F4F4F4",
    paddingBottom: "400px",
  },
  banner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  viewImage: {
    maxHeight: "700px",
    width: "100%",
    pointerEvents: "none",
  },
  viewImagetwo: {
    maxHeight: "500px",
    pointerEvents: "none",
    position: "absolute",
    right: "15%",
    bottom: "-10%",
  },

  leftSection: {
    width: "90%",
    margin: "auto",
    display: "flex",
    marginTop: 50,
  },
  title: {
    width: "90%",
    margin: "auto",
    marginTop: 0,
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "35px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
  },
  sectionInput: {
    marginTop: 20,
    padding: "5px 5px",
    borderRadius: "30px 30px",
  },
  InputHome: {
    border: "none",
    marginTop: 20,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "300",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
  },
});

const SectionOne = ({ setButton }) => {
  const classes = useStyles();
  const { plans: plansApi } = useGetPlans();

  return (
    <div>
      <SectionMain noPadding className={classes.container}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <img src={woman} className={classes.viewImage} />
        </div>
        <SectionMain className={classes.banner}>
          <SectionMain className={classes.leftSection}>
            <div className={classes.title}>
              O presente que tranforma vidas <br />
              <span className={classes.titleTwo}>
                Vale um ano inteiro de equilíbrio, paz e autocuidado.
              </span>
              <br />
              <br />
              <span className={classes.description}>
                Surpreenda quem você ama com um presente único: 365 dias de
                meditações guiadas, exercícios revitalizantes e práticas que
                elevam o bem-estar.
                <br />
                <br />
                Com o MeditaBem, você oferece muito mais que um presente você
                transforma rotinas e ajuda a criar dias mais leves e
                equilibrados.
                <br />
                <br />
                <b>
                  Oferta exclusiva: R${plansApi[1]?.value || "-"} por um ano de
                  acesso premium.
                </b>
              </span>
              <div className={classes.sectionInput}>
                <ButtonMedita
                  style={{
                    width: "90%",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setButton(true);
                  }}
                >
                  {"Compre Agora e Surpreenda!"}
                </ButtonMedita>
              </div>
            </div>
          </SectionMain>
        </SectionMain>
      </SectionMain>
    </div>
  );
};

export default SectionOne;
