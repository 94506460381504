import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";
import simbolo from "../../../../assets/images/home/simbolo.png";
import card from "../../../../assets/images/giftcards/card_price.png";
import SectionMain from "../../../../components/SectionMain";
import useGetPlans from "../../../../hooks/useGetPlans";

const useStyles = makeStyles({
  container: {
    marginTop: "50px",
    position: "relative",
    background: "#F4F4F4",
  },
  banner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  avatarCard: {
    width: "auto",
    height: "100px",
    borderRadius: "16px",
    margin: "auto",
    marginTop: "-50px",
  },
  viewImage: {
    maxHeight: "700px",
    pointerEvents: "none",
    right: "15%",
    bottom: "-10%",
  },
  leftSection: {
    width: "90%",
    margin: "auto",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "35px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#36b37e",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#5a7184",
  },
  descriptionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,
    marginRight: 10,
    textAlign: "left",
  },
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
    background: "#6eb64b",
    background: "linear-gradient(180deg,  #6eb64b 0.00%, #009daa 100.00%)",
  },
  avatarTop: {
    width: "45px",
    height: "45px",
    borderRadius: "16px",
  },
  avatarTop: {
    width: "45px",
    height: "45px",
    borderRadius: "16px",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
    marginTop: 20,
  },
  descItems: {
    width: "85%",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  align: {
    textAlign: "left",
    marginBottom: 20,
    marginLeft: 10,
  },
  viewCenter: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  margin: { marginTop: 50 },
  color: { color: "#fff" },
  typeText: {
    fontFamily: "Century Gothic",
    fontSize: 14,
    opacity: 1,
    letterSpacing: 1.5,
    color: "#6D6E70",
    fontWeight: "normal",
    marginTop: "3%",
  },
  typeTextTwo: {
    fontFamily: "Century Gothic",
    fontSize: 14,
    opacity: 1,
    letterSpacing: 1.5,
    color: "#6D6E70",
    fontWeight: "normal",
    marginTop: "3%",
    marginLeft: "3%",
  },
});

const items = [
  "365 dias de acesso completo ao MeditaBem.",
  "Meditações guiadas para relaxamento, foco e equilíbrio emocional.",
  "Yoga e alongamentos guiados para corpo e mente.",
  "Playlists personalizáveis para criar momentos únicos.",
  "Sem anúncios para uma experiência 100% focada no bem-estar.",
];

const SectionThree = () => {
  const classes = useStyles();
  const { plans: plansApi } = useGetPlans();

  return (
    <SectionMain noPadding className={classes.container}>
      <Avatar className={classes.avatarCard} alt={`icon-card`} src={card} />

      <SectionMain className={classes.banner}>
        <SectionMain className={classes.leftSection}>
          <div className={classes.description}>
            Benefícios do Vale Presente
            <br />
            <div>
              <span className={classes.titleTwo}>O que está incluso?</span>
              <br />
              <br />
              <div style={{ marginTop: 10 }}>
                {items.map((item) => (
                  <span className={classes.descriptionTwo}>
                    <div className={classes.viewCenter}>
                      <Avatar alt={`icon-simbolo`} src={simbolo} />
                      <span className={classes.align}>{item}</span>
                    </div>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default SectionThree;
